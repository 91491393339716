<template>
  <div class="mobilePage">
    <div class="header">
      <div class="logo"></div>
      <div class="telegram" @click="toTg"></div>
      <div class="potato" @click="toPotato"></div>
      <div
        class="download"
        @click="getApkInfo(os.isPhone ? 'ios' : 'android')"
      ></div>
    </div>
    <div class="main">
      <div class="figure"></div>
      <van-tabs
        v-model="active"
        class="tab"
        title-active-color="#ffffff"
        title-inactive-color="#ffffff"
        animated
        sticky
        offset-top="69px"
      >
        <van-tab title="漫画">
          <div
            class="content1"
            @click="getApkInfo(os.isPhone ? 'ios' : 'android')"
          ></div>
        </van-tab>
        <van-tab title="动漫">
          <div
            class="content2"
            @click="getApkInfo(os.isPhone ? 'ios' : 'android')"
          ></div>
        </van-tab>
        <van-tab title="真人视频">
          <div
            class="content3"
            @click="getApkInfo(os.isPhone ? 'ios' : 'android')"
          ></div>
        </van-tab>
      </van-tabs>
    </div>
    <!-- 安卓提示框 -->
    <van-overlay :show="androidPromptShow" z-index="999" class="androidOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="confirmBtnBox" @click="androidPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <!-- 苹果提示框 -->
    <van-overlay :show="iosPromptShow" z-index="999" class="iosOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="confirmBtnBox" @click="iosPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>

    <van-popup v-model="showTip" class="downTip">
      <div class="downTipTitle">温馨提示</div>
      <div class="downTipContent">请使用Safari浏览器进行下载</div>
      <div class="url">{{ currUrl }}</div>
      <div class="downTipConfirm" @click.stop="doCopy">复制链接</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ["os"],
  data() {
    return {
      androidPromptShow: false,
      iosPromptShow: false,
      showTip: false,
      currUrl: window.location.href,
      swiperOption: {
        autoplay: {
          delay: 2000,
          // stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        autoplayDisableOnInteraction: false,
        autoplayStopOnLast: false,
        // direction: "swiperOption",
        speed: 750,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        loop: true, // 循环模式选项
      },
      active: 0,
      isBottom: false,
    };
  },
  mounted() {
    let ua = this.os.userAgent.toLowerCase();
    let isSafari =
      ua.indexOf("safari") !== -1 &&
      ua.indexOf("browser") === -1 &&
      ua.indexOf("android") === -1;
    // alert(ua)
    console.log(ua);
    console.log(isSafari);
    // console.log(ua.indexOf('safari'), "------");
    if (!isSafari && this.os.isPhone) {
      this.showTip = true;
      // console.log(this.showTip, '-------------')
      // alert('请使用Safari浏览器进行下载')
    }
    const referrer = sessionStorage.getItem("referrer");
    const dc = sessionStorage.getItem("dc");
    if (referrer && dc) {
      this.currUrl = referrer + "user.html?dc=" + dc;
    } else {
      if (referrer) {
        this.currUrl = referrer + "user.html";
      } else {
        this.currUrl = window.location.href;
      }
    }
  },
  methods: {
    scroll(e) {
      const clientHeight = e.target.clientHeight;
      const scrollHeight = e.target.scrollHeight;
      const scrollTop = e.target.scrollTop;
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("到底了!");
        this.isBottom = true;
      } else {
        this.isBottom = false;
      }
      console.log(this.isBottom, "clientHeight");
      // console.log(scrollHeight, "scrollHeight");
      // console.log(scrollTop, "scrollTop");
    },
    // 剪贴板
    doCopy() {
      // let url = window.location.href;
      this.$copyText(this.currUrl).then(
        () => {
          console.log("复制成功");
          this.showTip = false;
        },
        () => {
          console.log("复制失败");
        }
      );
    },
    async showInstallTutorial(type) {
      if (type == "ios") {
        this.iosPromptShow = true;
      } else {
        this.androidPromptShow = true;
      }
    },
    async getApkInfo(type) {
      await this.showInstallTutorial(type);
      setTimeout(() => {
        this.$emit("getApkInfo", type);
      }, 500);
    },
    toTg() {
      window.open("https://t.me/semantiantang");
    },
    toPotato() {
      window.open("https://pt9.app/semantiantang");
    },
    jumpOfficialWeb() {
      window.open("https://eros01.com");
    },
  },
};
</script>

<style lang="scss" scoped>
.mobilePage {
  height: 100%;
  width: 100%;
  background: url("./../../../assets/images/mobile1/bg.webp") no-repeat;
  background-size: 100% 100%;
  // background-position: 0 -20px;
  overflow-y: auto;
  padding: 467px 10px 22px;
  box-sizing: border-box;
  ::deep .van-sticky {
    top: 60px;
  }
  .header {
    height: 58px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px 10px 26px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba(0, 0, 0, 1);
    .logo {
      height: 30px;
      width: 112px;
      background: url("./../../../assets/images/mobile1/logo.png") no-repeat;
      background-size: 100% 100%;
    }
    .telegram {
      width: 39px;
      height: 28px;
      background: url("./../../../assets/images/mobile1/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-left: 10px;
    }
    .potato {
      width: 39px;
      height: 28px;
      background: url("./../../../assets/images/mobile1/potato.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 10px;
    }
    .download {
      width: 95px;
      height: 26px;
      background: url("./../../../assets/images/mobile1/download.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .main {
    position: relative;
    .figure {
      height: 390.5px;
      width: 100%;
      background: url("./../../../assets/images/mobile1/figure.webp") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 0;
      top: -360px;
    }
    .tab {
      ::v-deep .van-tabs__wrap {
        background: rgba(0, 0, 0, 0.38);
        width: 294px !important;
        height: 33px !important;
        margin: 0 auto;
        border-radius: 16.5px;
        .van-tabs__nav {
          background: rgba(0, 0, 0, 0.38);
        }
        .van-tab--active {
          border-radius: 16.5px;
          background: rgba(255, 105, 32, 0.6);
        }
        .van-tabs__line {
          display: none;
        }
      }
      .content1 {
        margin-top: 11px;
        width: 100%;
        height: 1389px;
        background-image: url("../../../assets/images/mobile1/content1.webp");
        background-size: 100% 100%;
      }
      .content2 {
        margin-top: 11px;
        width: 100%;
        height: 1389px;
        background-image: url("../../../assets/images/mobile1/content2.webp");
        background-size: 100% 100%;
      }
      .content3 {
        margin-top: 11px;
        width: 100%;
        height: 1055px;
        background-image: url("../../../assets/images/mobile1/content3.webp");
        background-size: 100% 100%;
      }
    }
  }

  .boxSize {
    height: 63px;
  }
  .bottomBox {
    height: 110px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .bottomTip {
      height: 15px;
      width: 240px;
      background: url("./../../../assets/images/mobile1/bottomTip.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .safariTip {
      height: 15px;
      width: 183px;
      background: url("./../../../assets/images/mobile1/safariTip.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .downloadBox {
      height: 44px;
      width: 164px;
      background: url("./../../../assets/images/mobile1/downloadBtn.png")
        no-repeat;
      background-size: 100% 100%;
      margin: 7px auto 13px;
    }
  }
  .androidOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/android_tip.png")
        no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
  .iosOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/iosTip.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
}
.iosBg {
  background: url("./../../../assets/images/mobile1/bg.png") no-repeat;
  background-size: 100% 100%;
}
.downTip {
  border-radius: 10px;
  padding: 18px;
  box-sizing: border-box;
  text-align: center;
  .downTipTitle {
    font-size: 18px;
    margin-bottom: 34px;
  }
  .downTipContent {
    white-space: nowrap;
    font-size: 15px;
  }
  .url {
    font-size: 12px;
    margin-top: 16px;
    text-align: center;
  }
  .downTipConfirm {
    // margin-top: 12px;
    font-size: 15px;
    width: 160px;
    height: 30px;
    border-radius: 40px;
    margin: 32px auto 0;
    color: #fff;
    background: #5b92ee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.copywriting {
  width: 339px;
  height: 135px;
  background-image: url("../../../assets/images/mobile1/copywriting.png");
  background-size: 100% 100%;
  position: fixed;
  left: 50%;
  bottom: 122px;
  transform: translateX(-50%);
}
.figureSwiper {
  width: 375px;
  height: 469px;
  position: absolute;
  // top: 179px;
  // bottom: 97px;
  // top: 50%;
  top: 69px;
  left: 50%;
  // transform: translateX(-50%);
  transform: translate(-50%, 0);
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
.overflowY {
  overflow-y: auto !important;
}
::deep .van-sticky--fixed {
  top: 69px !important;
}
</style>
